import {AppInsightsAdapter} from './processors'

const getDeviceName = () => /\(([^)]+)\)/.exec(navigator.userAgent)?.[1]

type TrackEventData = {
  userId?: string | undefined
  [key: string]: unknown
}

type TrackEvent = (name: string, data: TrackEventData) => void

/**
 * Send custom analytics events
 */
export const trackEvent: TrackEvent = (name, data) => {
  AppInsightsAdapter.trackEvent(name, data)
}

export const trackEventWithBrowserProps: TrackEvent = (name, data) => {
  trackEvent(name, {
    ...data,
    device: getDeviceName(),
    operatingSystem: window.navigator.userAgent,
    screenResolution: {
      width: window.screen.availWidth,
      height: window.screen.availHeight,
      textual: `${window.screen.availWidth}x${window.screen.availHeight}`
    },
    viewportSize: {
      width: window.innerWidth,
      height: window.innerHeight,
      textual: `${window.innerWidth}x${window.innerHeight}`
    }
  })
}
